

























































import { Component, Vue } from "vue-property-decorator";
import { namespace } from "vuex-class";
const Auth = namespace("Auth");

@Component
export default class Login extends Vue {
  private user: any = { username: "", password: "" };
  private loading: boolean = false;
  private message: string = "";
  private valid: boolean = true;
  private showpassword: boolean = false;

  private requiredRule(value: string): any {
    return !!value || "Required.";
  }

  @Auth.Getter
  private isLoggedIn!: boolean;

  @Auth.Action
  private login!: (data: any) => Promise<any>;

  created() {
    if (this.isLoggedIn) {
      this.$router.push("/files");
    }
  }

  handleLogin() {
    this.loading = true;
    if ((this.$refs.loginForm as any).validate()) {
      this.login(this.user).then(
        (data) => {
          this.$router.push("/files");
        },
        (error) => {
          this.loading = false;
          this.message = "Wrong login informations";
        }
      );
    }
  }
}

import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store/index";
import vuetify from "./plugins/vuetify";
import VueMoment from "vue-moment";
import moment from "moment-timezone";

moment.tz.setDefault("UTC");

Vue.config.productionTip = false;

Vue.use(VueMoment, {
  moment,
});

new Vue({
  router,
  store,
  vuetify,
  render: (h) => h(App),
}).$mount("#app");

document.title = "Andromeda Web";

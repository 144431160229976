




























































































































import { Component, Provide, Vue, Watch } from "vue-property-decorator";
import { namespace } from "vuex-class";
const Auth = namespace("Auth");

import environment from "./environment";
import { BackendClient } from "ibf-backend";

@Component
export default class App extends Vue {
  @Provide("backendClient")
  backendClientService: BackendClient = new BackendClient(
    environment.backendUrl
  );

  @Auth.State("user")
  private currentUser!: any;

  private drawer: boolean = true;
  private devMode = environment.devMode;

  private availableTasks: Array<String> = [];

  mounted() {
    this.populateAvailableTasks();
  }

  @Watch("currentUser")
  public userChange() {
    this.populateAvailableTasks();
  }

  async populateAvailableTasks() {
    this.availableTasks = (
      await this.backendClientService.get("/task/availableTasks")
    ).data;
  }

  isAvailable(taskName: string): boolean {
    if (this.availableTasks == null) {
      return false;
    }
    return (
      this.availableTasks.find(
        (t) => t.toLowerCase() == taskName.toLowerCase()
      ) != undefined
    );
  }

  @Auth.Action
  private signOut!: () => void;

  logOut() {
    if (confirm("Do you really want to log out ?")) {
      this.signOut();
      this.$router.push("/login");
    }
  }
}

import { VAlert } from 'vuetify/lib/components/VAlert';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VForm } from 'vuetify/lib/components/VForm';
import { VProgressCircular } from 'vuetify/lib/components/VProgressCircular';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"col-md-12"},[_c(VCard,{staticClass:"px-4 mx-auto",staticStyle:{"max-width":"500px"}},[_c(VCardText,[(_vm.message)?_c(VAlert,{attrs:{"border":"right","colored-border":"","type":"error","elevation":"2"}},[_vm._v(_vm._s(_vm.message))]):_vm._e(),_c(VForm,{ref:"loginForm",attrs:{"lazy-validation":""},model:{value:(_vm.valid),callback:function ($$v) {_vm.valid=$$v},expression:"valid"}},[_c(VRow,[_c(VCol,{attrs:{"cols":"12"}},[_c(VTextField,{attrs:{"label":"User","rules":[_vm.requiredRule]},model:{value:(_vm.user.username),callback:function ($$v) {_vm.$set(_vm.user, "username", $$v)},expression:"user.username"}})],1),_c(VCol,{attrs:{"cols":"12"}},[_c(VTextField,{attrs:{"append-icon":_vm.showpassword ? 'eye' : 'eye-off',"rules":[_vm.requiredRule],"type":"password","name":"input-10-1","label":"Password"},model:{value:(_vm.user.password),callback:function ($$v) {_vm.$set(_vm.user, "password", $$v)},expression:"user.password"}})],1),_c(VSpacer),_c(VCol,{attrs:{"cols":"3","align-end":""}},[_c(VBtn,{attrs:{"block":"","disabled":!_vm.valid,"color":"success"},on:{"click":_vm.handleLogin}},[_vm._v(" Connexion ")])],1),_c(VCol,{attrs:{"cols":"3"}},[_c(VProgressCircular,{directives:[{name:"show",rawName:"v-show",value:(_vm.loading),expression:"loading"}],staticClass:"ml-4",attrs:{"indeterminate":"","color":"green"}})],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }






























import { Component, Vue } from "vue-property-decorator";
import { namespace } from "vuex-class";
const Auth = namespace("Auth");

@Component
export default class HelloWorld extends Vue {
  @Auth.State("user")
  private currentUser!: any;
}

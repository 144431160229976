import Vue from "vue";
import VueRouter, { RouteConfig } from "vue-router";
import Home from "@/components/Home.vue";
import Login from "@/components/Login.vue";
import ServerError from "@/components/ServerError.vue";
import environment from "@/environment";
import { BackendClient } from "ibf-backend";
import PsrViewer from "@/apps/psr/PSRViewer.vue";

import store from "@/store";

Vue.use(VueRouter);

const routes: Array<RouteConfig> = [
  {
    path: "/",
    name: "home",
    component: Home,
  },
  {
    path: "/login",
    component: Login,
  },
  {
    path: "/servererror",
    component: ServerError,
  },
  {
    path: "/files/:path*",
    name: "files",
    // lazy-loaded
    component: () => import("@/components/files/Files.vue"),
  },
  {
    path: "/psr/:filePath*",
    name: "psr",
    // lazy-loaded
    component: () => import("@/apps/psr/PSRViewer.vue"),
  },
  {
    path: "/tasks",
    name: "tasks",
    // lazy-loaded
    component: () => import("@/components/tasks/Tasks.vue"),
  },
  {
    path: "/task/:id",
    name: "task",
    component: () => import("@/components/tasks/TaskComponent.vue"),
  },
  {
    path: "/apps/rpptopsa",
    name: "rpptopsa",
    component: () => import("@/apps/rpptopsa/RppToPsa.vue"),
  },
  {
    path: "/apps/psatorpp",
    name: "psatorpp",
    component: () => import("@/apps/psatorpp/PsaToRpp.vue"),
  },
  {
    path: "/apps/cutsetanalyses",
    name: "cutsetanalyses",
    component: () => import("@/apps/cutsetanalyses/CutsetAnalyses.vue"),
  },
  {
    path: "/apps/PSAQuantification",
    name: "PSAQuantification",
    component: () =>
      import("@/apps/PSAQuantification/PSAQuantificationLauncher.vue"),
  },
  {
    path: "/profile",
    name: "Profile",
    // lazy-loaded
    component: () => import("@/components/profile/Profile.vue"),
  },
];

const router = new VueRouter({
  base: process.env.BASE_URL,
  routes,
});

const backendClient = new BackendClient(environment.backendUrl);

router.beforeEach((to, from, next) => {
  const publicPages = ["/login", "/servererror"];
  const authRequired = !publicPages.includes(to.path);

  if ("/servererror" === to.path) {
    next();
    return;
  }

  // check if token has expired / server is ok
  backendClient.get("/server/ping").then(
    (response) => {
      if (
        response.data === "NOT_LOGGED" &&
        localStorage.getItem("user") != null
      ) {
        store.dispatch("Auth/signOut");
      }

      if (!response.data.needAuth && response.data.logged) {
        console.log(
          router.app.$store.commit("Auth/loginSuccess", {
            user: response.data.userName,
            token: "",
          })
        );
      }

      // trying to access a restricted page + not logged in
      // redirect to login page
      if (authRequired && response.data.needAuth && !response.data.logged) {
        next("/login");
      } else {
        next();
      }
    },
    (error) => {
      if (error.response.status === 403) {
        // If we have a 403, we try to ping with an expirated or wrong token
        // so disconnect
        store.dispatch("Auth/signOut");
        next("/login");
      } else {
        next("/servererror");
      }
    }
  );
});

export default router;
